import { ThemeProvider } from '@mui/material/styles';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect } from 'react';
import { initReactI18next, useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import italianI18n from '../src/assets/i18n/ezwork-fe_IT-it.json';
import { EZ_THEME } from '../src/EzTheme';
import './App.scss';
import { store } from './app/store';
import EzToaster from './components/core/ez-toaster/EzToaster';
import { momentITLocale } from './moment.config';
import { ClientListView } from './pages/clients-list/ClientsListView';
import { DashboardView } from './pages/dashboard/DashboardView';
import { LoginView } from './pages/login/LoginView';
import { OrdersListView } from './pages/orders/OrdersListView';
import { RegisterView } from './pages/register/RegisterView';
import { AuthRoute } from './pages/security/auth-route/AuthRoute';
import { PublicRoute } from './pages/security/public-route/PublicRoute';
import { Settings } from './pages/settings/settings';
import { Stats } from './pages/stats/Stats';
import { Targets } from './pages/targets/Targets';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import EzPage from './components/ez-page/EzPage';
import { Revenue } from './pages/revenue/Revenue';

const TITLE_PREFIX: string = 'EzWork -';
i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      it: {
        translation: italianI18n,
      },
    },
    lng: 'it',
    fallbackLng: 'it',
    interpolation: {
      escapeValue: false,
    },
  });

moment.locale('it', momentITLocale);

function App() {
  const { t } = useTranslation();
  useEffect(() => {
    document.body.style.fontFamily = '"Roboto", sans-serif';
    document.querySelector('html')?.setAttribute('data-theme', 'light');
  });

  return (
    <React.StrictMode>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={EZ_THEME}>
            <BrowserRouter>
              <EzToaster />
              <Routes>
                <Route element={<PublicRoute />}>
                  <Route
                    path="/"
                    element={
                      <EzPage title={`${TITLE_PREFIX} ${t('PAGES.LOGIN_TITLE')}`}>
                        <LoginView />
                      </EzPage>
                    }
                  />
                  <Route
                    path="login"
                    element={
                      <EzPage title={`${TITLE_PREFIX} ${t('PAGES.LOGIN_TITLE')}`}>
                        <LoginView />
                      </EzPage>
                    }
                  />
                  <Route path="register" element={<RegisterView />} />
                </Route>
                <Route element={<AuthRoute />}>
                  <Route
                    path="dashboard"
                    element={
                      <EzPage title={`${TITLE_PREFIX} ${t('PAGES.DASHBOARD_TITLE')}`}>
                        <DashboardView />
                      </EzPage>
                    }
                  />
                  <Route
                    path="clients"
                    element={
                      <EzPage title={`${TITLE_PREFIX} ${t('CLIENTS_LIST.PAGE_TITLE')}`}>
                        <ClientListView />
                      </EzPage>
                    }
                  />
                  <Route
                    path="orders"
                    element={
                      <EzPage title={`${TITLE_PREFIX} ${t('ORDERS.PAGE_TITLE')}`}>
                        <OrdersListView />
                      </EzPage>
                    }
                  />
                  <Route
                    path="stats"
                    element={
                      <EzPage title={`${TITLE_PREFIX} ${t('STATS.PAGE_TITLE')}`}>
                        <Stats />
                      </EzPage>
                    }
                  />
                  <Route
                    path="settings"
                    element={
                      <EzPage title={`${TITLE_PREFIX} ${t('PAGES.SETTINGS_TITLE')}`}>
                        <Settings />
                      </EzPage>
                    }
                  />
                  <Route
                    path="revenue"
                    element={
                      <EzPage title={`${TITLE_PREFIX} ${t('REVENUE.PAGE_TITLE')}`}>
                        <Revenue />
                      </EzPage>
                    }
                  />
                  <Route
                    path="targets"
                    element={
                      <EzPage title={`${TITLE_PREFIX} ${t('TARGETS.PAGE_TITLE')}`}>
                        <Targets />
                      </EzPage>
                    }
                  />
                </Route>
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </LocalizationProvider>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
