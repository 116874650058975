import crudActions from '../common/crud.actions.abs';
import revenuesService from './revenues.service';

const { getAllAction, deleteAction, updateAction } = crudActions('revenue', revenuesService);

export {
	getAllAction as getAllRevenuesAction,
	updateAction as updateRevenueAction,
	deleteAction as deleteRevenueAction
};
