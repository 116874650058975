import { t } from 'i18next';
import moment from 'moment';
import { IoWarningOutline } from 'react-icons/io5';
import { EzModal } from '../../../components/core/ez-modal/EzModal';
import { deleteTargetAction } from '../../../features/targets/targets.actions';
import { useAppDispatch } from '../../../hooks/redux-toolkit.hook';
import { useToast } from '../../../hooks/toaster.hook';
import { RevenueDTO } from '../../../om/dto/revenues/revenue.dto';
import { deleteRevenueAction } from '../../../features/revenues/revenues.actions';
import { useTranslation } from 'react-i18next';

export interface DeleteTargetModalProps {
	modalId: string;
	revenueYearToDelete: number | undefined;
	callback: any;
	isOpen: boolean;
	closeModalCallback: () => void;
}

export const DeleteRevenueModal = ({
	modalId,
	callback,
	isOpen,
	revenueYearToDelete,
	closeModalCallback
}: DeleteTargetModalProps) => {
	const { t } = useTranslation();
	const toaster = useToast();
	const dispatch = useAppDispatch();
	const dispatchDeleteRevenue = async (e: any) => {
		e.preventDefault();
		const result = await dispatch(deleteRevenueAction(revenueYearToDelete));
		if (result.error) toaster.error(result.error?.error);
		else {
			callback();
			toaster.success(`${t('REVENUE.TOOLTIP.CONFIRMED_DELETE')}`);
			closeModalCallback();
		}
	};

	const deleteRevenueModalActions = () => (
		<button onClick={dispatchDeleteRevenue} className='btn btn-primary w-32'>
			{t('COMMON.CONFIRM')}
		</button>
	);

	return (
		<EzModal
			isOpen={isOpen}
			id={modalId}
			actions={deleteRevenueModalActions()}
			closeCustomLabel='Annulla'
			closeCallback={closeModalCallback}
		>
			<p className='py-4 text-center'>
				<div className='flex justify-center mb-5'>
					<IoWarningOutline className='text-warning' size={'5rem'} />
				</div>
				<div className='text-lg'>
					Sei sicuro di voler eliminare tutti i fatturati dell'anno <b>{revenueYearToDelete}</b> ?
					<br />
					L'operazione non è reversibile.
				</div>
			</p>
		</EzModal>
	);
};
