import { Add, Edit } from '@mui/icons-material';
import { Button } from '@mui/material';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { EzCard } from '../../components/core/ez-card/EzCard';
import { EzHeading } from '../../components/core/ez-heading/EzHeading';
import { EzTable } from '../../components/core/ez-table/EzTable';
import { useAppSelector, useModal } from '../../hooks';
import { EzTableColumn } from '../../om';
import { EzTableColumnType } from '../../om/enum/ez-table-column-type.enum';
import useCurrency from '../../utils/currency.util';
import { RevenueDiagram } from './revenue-diagram/RevenueDiagram';
import { RevenueFormModal } from './revenue-form-modal/RevenueFormModal';
import './revenue.scss';
import { getAllRevenuesAction } from '../../features/revenues/revenues.actions';
import { RevenueDTO } from '../../om/dto/revenues/revenue.dto';
import { RevenueState } from '../../om/features/revenue.state';

export function Revenue() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { revenues, revenuesTableData }: RevenueState = useAppSelector((state: any) => state.revenues);
	const [revenueYearToEdit, setRevenueYearToEdit] = useState<number | undefined>(undefined);
	const { formatCurrency } = useCurrency();

	const [isRevenueFormModalOpen, openRevenueFormModal, closeRevenueFormModal] = useModal();

	useEffect(() => {
		dispatch(getAllRevenuesAction());
	}, []);

	const openRevenueEditFormModal = () => {
		setRevenueYearToEdit(getRevenueYears().pop());
		openRevenueFormModal();
	};

	const closeEditRevenueFormModal = () => {
		setRevenueYearToEdit(undefined);
		closeRevenueFormModal();
	};

	const onUpdateRevenueCallback = async () => {
		await dispatch(getAllRevenuesAction());
	};

	const getRevenueYears = (): number[] => Array.from(new Set(revenues.map((item: RevenueDTO) => item.year)));

	const renderColumnAmount = (item: any, amountField: string) => {
		return <div className='revenues__amount'>{formatCurrency(item[amountField] ?? '')}</div>;
	};

	const getTableColumnSettings = (): Array<EzTableColumn> => {
		const revenueYears = getRevenueYears();
		return [
			{
				id: 'month',
				label: 'Mese',
				columnType: EzTableColumnType.TEXT
			},
			...revenueYears.map(
				(year) =>
					({
						id: year.toString(),
						label: year.toString(),
						columnType: EzTableColumnType.CUSTOM,
						customColumn: (item) => renderColumnAmount(item, year.toString())
					} as EzTableColumn)
			)
		];
	};

	return (
		<Fragment>
			<div className='revenues__container mt-5'>
				<EzCard>
					<EzHeading
						title={t('REVENUE.PAGE_TITLE')}
						contentEnd={
							<div className='flex gap-4'>
								<Button
									onClick={openRevenueEditFormModal}
									startIcon={<Edit />}
									variant='outlined'
									color='primary'
									type='submit'
									size='large'
								>
									{t('REVENUE.EDIT_LABEL')}
								</Button>
								<Button
									onClick={openRevenueFormModal}
									startIcon={<Add />}
									variant='contained'
									color='primary'
									type='submit'
									size='large'
								>
									{t('REVENUE.ADD_LABEL')}
								</Button>
							</div>
						}
					/>
					<RevenueDiagram />
					<EzTable id='revenues-list' columns={getTableColumnSettings()} data={revenuesTableData} isPaginated={false} />
				</EzCard>
			</div>
			<RevenueFormModal
				modalId='revenue-form-modal'
				isOpen={isRevenueFormModalOpen}
				revenueYearToEdit={revenueYearToEdit}
				revenueYears={getRevenueYears()}
				callback={onUpdateRevenueCallback}
				closeModalCallback={closeEditRevenueFormModal}
				isLoading={false}
			/>
		</Fragment>
	);
}
