import { useEffect, useRef, useState } from 'react';
import { EzTableColumnType } from '../../../om/enum/ez-table-column-type.enum';
import { EzTableAction } from '../../../om/ez-table-action';
import { EzTableHeading } from './ez-table-heading/EzTableHeading';
import { EzTableItem } from './ez-table-item/EzTableItem';
import { EzTableDataActions } from './ez-table-actions/EzTableDataActions';
import { EzTablePagination } from './ez-table-pagination/EzTablePagination';
import './ez-table.scss';
import { useTranslation } from 'react-i18next';
import { EzTableColumn } from '../../../om/ez-table-column';
import { Box, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';

interface EzTableProps {
  id: string;
  columns: Array<EzTableColumn>;
  data: Array<any>;
  actions?: Array<EzTableAction>;
  customHeadingClasses?: string;
  isPaginated?: boolean;
}

export function EzTable({
  id,
  data,
  actions,
  columns,
  customHeadingClasses,
  isPaginated = true,
}: Readonly<EzTableProps>) {
  const tableColumns: Array<EzTableColumn> = [
    ...columns,
    ...(actions ? [{ id: 'actions', label: '', columnType: EzTableColumnType.ACTIONS }] : []),
  ];
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(itemsPerPage);
  const [pagedData, setPagedData] = useState([...data].slice(start, end));
  const { t } = useTranslation();
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    setPagedData([...data].slice(start, isPaginated ? end : data.length));
  }, [data]);

  const updatePagedData = (start: number, end: number) => {
    setStart(start);
    setEnd(end);
    setPagedData([...data].slice(start, end));
  };

  const onClickNextPage = () => {
    const newStart: number = start + itemsPerPage;
    const newEnd: number = end + itemsPerPage;
    updatePagedData(newStart, newEnd);
  };

  const onClickPrevPage = () => {
    const newStart: number = start - itemsPerPage;
    const newEnd: number = end - itemsPerPage;
    updatePagedData(newStart, newEnd);
  };

  const onNumItemsChange = (newValue: number) => {
    setItemsPerPage(+newValue);
    updatePagedData(0, +newValue);
  };

  const updateScrollLeft = (event: any) => {
    setScrollLeft(event?.target?.scrollLeft || 0);
  };

  const showTablePagination: boolean | undefined = data && data.length > 0 && isPaginated;

  return (
    <div className="ez-table">
      <Box
        onScroll={updateScrollLeft}
        className={`ez-table__body ${scrollLeft > 0 ? 'ez-table__body--scrolled' : ''}`}
        sx={{ overflow: 'auto' }}
      >
        <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
          <Table id={id} className="ez-table__table">
            <TableHead>
              <TableRow>
                {tableColumns.map((column) => (
                  <EzTableHeading column={column} customClasses={customHeadingClasses} />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.length > 0
                ? pagedData.map((item) => (
                    <TableRow className="ez-table__row">
                      {columns.map((column) => (
                        <EzTableItem item={item} columnInfo={column} />
                      ))}
                      <EzTableDataActions item={item} actions={actions} />
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </Box>
      </Box>
      {showTablePagination ? (
        <EzTablePagination
          numItemsList={[10, 25, 50, 100]}
          onNumItemsChange={onNumItemsChange}
          data={data}
          pagedData={pagedData}
          onClickNext={onClickNextPage}
          onClickPrev={onClickPrevPage}
          start={start}
          end={end}
        />
      ) : null}
    </div>
  );
}
