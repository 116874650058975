import { createSlice } from '@reduxjs/toolkit';
import { TargetState } from '../../om/features/target.state';
import { RevenueState } from '../../om/features/revenue.state';
import { getAllRevenuesBuilders, updateRevenueBuilders } from './revenues.builders';

const initialState: RevenueState = {
	revenues: [],
	revenuesTableData: [],
	isGetAllRevenuesLoading: false,
	isUpdateRevenueSuccess: false
};

export const revenuesSlice = createSlice({
	name: 'targets',
	initialState,
	reducers: {
		reset: (state: RevenueState) => {
			state = initialState;
		}
	},
	extraReducers: (builder) => {
		getAllRevenuesBuilders(builder);
		updateRevenueBuilders(builder);
	}
});

export default revenuesSlice.reducer;
