import { FormControl, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React, { ReactElement, useState } from 'react';
import { MdEuroSymbol } from 'react-icons/md';
import { CommonEzInput } from '../../../om/common-ez-input';
import { EzInputValidator } from '../../../om/enum/ez-input-validator.enum';
import { EzErrorsLabel } from '../ez-errors-label/EzErrorsLabel';
import './ez-input.scss';

export interface EzInputProps extends CommonEzInput {
	type?: 'text' | 'date' | 'amount' | 'password';
	value: any;
	onChange: (e: any) => void;
	icon?: ReactElement;
}

const prefix = 'ez-input__field';

export function EzInput({
	name,
	onChange,
	value,
	label,
	placeholder,
	type = 'text',
	validators = type === 'amount' ? [EzInputValidator.AMOUNT] : [],
	icon,
}: EzInputProps) {
	const [isTouched, setIsTouched] = useState(false);

	const updateAmount = (e: any) => {
		if (!isTouched) setIsTouched(true);
		onChange(e);
	};

	const updateValue = (e: any) => {
		if (!isTouched) setIsTouched(true);
		onChange(e);
	};

	const updateDatepickerValue = (newInputValue: any) => {
		if (!isTouched) setIsTouched(true);
		onChange(newInputValue);
	};

	const renderIcon = () => {
		if (icon) {
			const _icon = React.cloneElement(icon);
			return _icon;
		}
	};

	const renderGenericInput = () => {
		if ('date' === type)
			return (
				<DatePicker
					label={label}
					slotProps={{ textField: { name: name } }}
					value={value}
					className={prefix}
					onChange={updateDatepickerValue}
				/>
			);
		return (
			<TextField
				variant="outlined"
				value={value}
				label={label}
				onChange={updateValue}
				name={name}
				type={type}
				placeholder={placeholder}
				className={`ez-input__field ${icon && 'ez-input__field-with-icon'}`}
			/>
		);
	};

	return (
		<div className={icon ? 'ez-input__with-icon' : 'ez-input'}>
			{icon && <span className="ez-input__icon">{renderIcon()}</span>}
			{type !== 'amount' ? (
				renderGenericInput()
			) : (
				<FormControl>
					<InputLabel id={`ezinput-amount-${name.toLowerCase()}`} htmlFor="component-simple">
						{label}
					</InputLabel>
					<OutlinedInput
						startAdornment={
							<InputAdornment position="start">
								<MdEuroSymbol size={22} />
							</InputAdornment>
						}
						value={value}
						onChange={updateAmount}
						name={name}
						type={type}
						placeholder="0"
						label={label}
						className={`ez-input__amount-field ${icon && 'ez-input__field-with-icon'}`}
						id={`ezinput-amount-${name.toLowerCase()}`}
					/>
				</FormControl>
			)}
			{isTouched && validators ? <EzErrorsLabel data={value} validators={validators || []} /> : null}
		</div>
	);
}
