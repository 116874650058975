import { useMemo } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RevenueState } from '../../../om/features/revenue.state';
import { useAppSelector } from '../../../hooks';
import useCurrency from '../../../utils/currency.util';

export function RevenueDiagram() {
	const { revenues }: RevenueState = useAppSelector((state: any) => state.revenues);
	const { formatCurrency } = useCurrency();

	return (
		<ResponsiveContainer width='100%' height={350}>
			<LineChart
				key='amount'
				width={500}
				height={300}
				data={revenues}
				margin={{
					top: 5,
					right: 30,
					bottom: 5
				}}
			>
				<CartesianGrid strokeDasharray='3 3' />
				<XAxis dataKey='label' name='Mese' />
				<YAxis />
				<Tooltip formatter={(value, name) => `${formatCurrency(+value)}`} />
				<Legend />
				<Line
					type='monotone'
					legendType='none'
					name='Importo'
					dataKey='amount'
					stroke='#3066BE'
					activeDot={{ r: 4 }}
					dot={{ r: 0 }}
				/>
			</LineChart>
		</ResponsiveContainer>
	);
}
